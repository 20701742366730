import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
};

const homeCardsSlice = createSlice({
    name: 'homeCards',
    initialState,
    reducers: {
        setHomeCards: (state, {payload}) => {
            state.data = payload;
        }
    }
});

export default homeCardsSlice.reducer;
export const {setHomeCards} = homeCardsSlice.actions;