import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
};

const hostedWebsiteCardsSlice = createSlice({
    name: 'hostedWebsiteCards',
    initialState,
    reducers: {
        setHostedWebsiteCards: (state, {payload}) => {
            state.data = payload;
        }
    }
});

export default hostedWebsiteCardsSlice.reducer;
export const {setHostedWebsiteCards} = hostedWebsiteCardsSlice.actions;