import { useSelector } from 'react-redux';
import GooglePlayImage from '../images/google-play.png';
import Card from '../components/Card';
import { Link } from 'react-router-dom';
import { borderedBackground, flex_col, flex_row } from '../helpers/classnames';
import React from 'react';

/**
 * Shows a page for Nichathan Gaming's Google Play projects with an array of articles about the projects.
 * @returns The Google Play page
 */
const GooglePlay = () => {
    const googlePlayGamesCards = useSelector(state=>state.googlePlayGamesCards);
    const googlePlayAppsCards = useSelector(state=>state.googlePlayAppsCards);

    return (
        <section
            className={`${flex_col} text-success`}
        >
            <article
                className={`${flex_row} m-auto justify-content-center text-center ${borderedBackground}`}
            >
                <img 
                    className='display-image'
                    src={GooglePlayImage}
                    alt="Google Play"
                />
                <div
                    className={`${flex_col} display-text`}
                >
                    <h2
                        className="fw-bolder fs-1"
                    >Nichathan Gaming on Google Play</h2>
                    <p>
                        <b>Nichathan Gaming</b> has been publishing games and 
                        applications to the Google Play store since early 
                        2023. These games and applications are portfolio 
                        projects meant to test the use of advertisements, 
                        firebase and more. They are all made in Unity with 
                        C# using a variety of assets. 
                    </p>
                    <div
                        className={flex_row}
                    >
                        <Link 
                            className='btn btn-success m-auto'
                            to='https://play.google.com/store/apps/dev?id=5505294983591200024'
                            target='_blank'
                        >View <b>Nichathan Gaming</b> on Google Play</Link>
                        <Link 
                            className='btn btn-success m-auto'
                            to='https://github.com/Nichathan-Gaming'
                            target='_blank'
                        >View <b>Nichathan Gaming</b> C# and Unity on GitHub</Link>
                    </div>
                </div>
            </article>
            <h2
                className={`fw-bolder m-auto ${borderedBackground}`}
            >Google Play Games</h2>
            <article
                className={`${flex_row} justify-content-center`}
            >
                {googlePlayGamesCards.data.map((card, index)=>{
                    return <Card 
                        key={index}
                        {...card}
                    />
                })}
            </article>
            <h2
                className={`fw-bolder m-auto ${borderedBackground}`}
            >Google Play Apps</h2>
            <article
                className={`justify-content-center ${flex_row}`}
            >
                {googlePlayAppsCards.data.map((card, index)=>{
                    return <Card 
                        key={index}
                        {...card}
                    />
                })}
            </article>
        </section>
    );
};

export default GooglePlay;