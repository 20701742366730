import { Link } from 'react-router-dom';
import Image from './Image';
import { borderedBackground, flex_col } from '../helpers/classnames';
import React from 'react';

/**
 * Creates a card component
 * @param param0 The card to display
 * @returns A card JSX component
 */
const Card = ({image, title, text, date, link}) => {
    return (        
        <div 
            className={`card overflow-hidden ${borderedBackground}`} 
        >
            <div 
                className="row g-0 h-100"
            >
                {image && <div 
                    className="col-md-4"
                >
                    <Image 
                        {...image}
                    />
                </div>}
                <div 
                    className={`col-md-8 card-body text-success ${flex_col}`}
                >
                    <h5 
                        className="card-title fw-bolder"
                    >{title}</h5>
                    <p 
                        className="card-text"
                    >{text}</p>
                    <div
                        className={`mt-auto ${flex_col}`}
                    >
                        {link && <Link 
                            to={link.linkString}
                            target='_blank'
                            className='btn btn-success ms-auto'
                        >{link.linkTitle}</Link>}
                        <p 
                            className="card-text"
                        ><small 
                            className="text-muted"
                        >
                            Posted {date}
                        </small></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;