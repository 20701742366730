import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';

/**
 * Creates a navbar
 * @returns A navbar jsx element
 */
const NavBar = () => {
    return (
        <nav 
            className="bg-opacity-75 sticky-top navbar navbar-expand-lg navbar-light bg-light border-bottom border-success rounded-bottom p-1"
        >
            <div 
                className="container-fluid gap-3"
            >
                <Link 
                    to='/'
                    className="text-success text-decoration-none fs-1 fw-bolder"
                >NG</Link>
                <button 
                    className="navbar-toggler border-success btn btn-outline-success hamburger-shell align-self-stretch"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" 
                    aria-controls="navbarSupportedContent" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation"
                >
                    <div
                        className='d-flex flex-column justify-content-evenly h-100'
                    >
                        <div 
                            className='rounded hamburger bg-success'
                        />
                        <div 
                            className='rounded hamburger bg-success'
                        />
                        <div 
                            className='rounded hamburger bg-success'
                        />
                    </div>
                </button>
                <Nav
                    id="navbarSupportedContent"
                    className='collapse navbar-collapse gap-3 justify-content-end'
                >
                    <LinkContainer
                        to='/'
                    >
                        <button
                            className="btn btn-outline-success"
                        >Home</button>
                    </LinkContainer>
                    <LinkContainer
                        to='/google-play'
                    >
                        <button
                            className="btn btn-outline-success"
                        >Google Play</button>
                    </LinkContainer>
                    <LinkContainer
                        to='/websites'
                    >
                        <button
                            className="btn btn-outline-success"
                        >Websites</button>
                    </LinkContainer>
                    <LinkContainer
                        to='/npm'
                    >
                        <button
                            className="btn btn-outline-success"
                        >NPM</button>
                    </LinkContainer>
                    <LinkContainer
                        to='/tutorials'
                    >
                        <button
                            className="btn btn-outline-success"
                        >Tutorials</button>
                    </LinkContainer>
                    <LinkContainer
                        to='/about'
                    >
                        <button
                            className="btn btn-outline-success"
                        >About</button>
                    </LinkContainer>
                </Nav>
            </div>
        </nav>
    );
};

export default NavBar;