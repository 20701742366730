import { Link } from "react-router-dom";
import npm from '../images/npm.png';
import Card from "../components/Card";
import { useSelector } from "react-redux";
import { borderedBackground, flex_col, flex_row } from "../helpers/classnames";
import React from "react";

/**
 * Shows a page for Nichathan Gaming's NPM projects with an array of articles about the projects.
 * @returns The NPM page
 */
const NPM = () => {
    const npmCards = useSelector(state=>state.npmCards);

    return (
        <section
            className={`${flex_col} text-success`}
        >
            <article
                className={`${flex_row} m-auto justify-content-center ${borderedBackground}`}
            >
                <img 
                    className='display-image'
                    src={npm}
                    alt="NPM"
                />
                <div
                    className={`${flex_col} display-text`}
                >
                    <h2
                        className="fw-bolder fs-1"
                    >Nichathan Gaming NPM Packages</h2>
                    <p>
                        <b>Nichathan Gaming</b> has been creating NPM packages since late 2024. 
                        The NPM packages are for React JS and React TS.
                    </p>
                    <Link 
                        className='btn btn-success m-auto'
                        to='https://www.npmjs.com/~nichathan-gaming'
                        target='_blank'
                    >View <b>Nichathan Gaming</b> on NPM</Link>
                </div>
            </article>
            <article
                className={`justify-content-center ${flex_row}`}
            >
                {npmCards.data.map((card, index)=>{
                    return <Card 
                        key={index}
                        {...card}
                    />
                })}
            </article>
        </section>
    );
};

export default NPM;