import { Link } from 'react-router-dom';
import WebDev from '../images/WebDev.png';
import Card from '../components/Card';
import { useSelector } from 'react-redux';
import { borderedBackground, flex_col, flex_row } from '../helpers/classnames';
import React from 'react';

/**
 * Shows a page for Nichathan Gaming's website projects with an array of articles about the projects.
 * @returns The Websites page
 */
const Websites = () => {
    const reactGameCards = useSelector(state=>state.reactGameCards);
    const hostedWebsiteCards = useSelector(state=>state.hostedWebsiteCards);

    return (
        <section
            className={`${flex_col} text-success`}
        >
            <article
                className={`${flex_row} m-auto justify-content-center ${borderedBackground}`}
            >
                <img 
                    className='display-image'
                    src={WebDev}
                    alt="WebDev"
                />
                <div
                    className={`${flex_col} display-text`}
                >
                    <h2
                        className="fw-bolder fs-1"
                    >Nichathan Gaming Websites</h2>
                    <p>
                        <b>Nichathan Gaming</b> has been creating full stack websites since 2019. Nichathan Gaming began creating
                        websites for friends, family and local small businesses. In the beginning, websites were created with 
                        React, Node and MySQL or MongoDB but now Nichathan Gaming is using React, TS-Node, Redux and Firebase.
                    </p>
                    <Link 
                        className='btn btn-success m-auto'
                        to='https://github.com/J-M-Nichols'
                        target='_blank'
                    >View <b>Nichathan Gaming</b> on Git Hub</Link>
                </div>
            </article>
            <h2
                className={`fw-bolder m-auto ${borderedBackground}`}
            >React Games</h2>
            <article
                className={`justify-content-center ${flex_row}`}
            >
                {reactGameCards.data.map((card, index)=>{
                    return <Card 
                        key={index}
                        {...card}
                    />
                })}
            </article>
            <h2
                className={`fw-bolder m-auto ${borderedBackground}`}
            >Hosted Websites</h2>
            <article
                className={`${flex_row} justify-content-center`}
            >
                {hostedWebsiteCards.data.map((card, index)=>{
                    return <Card 
                        key={index}
                        {...card}
                    />
                })}
            </article>
        </section>
    );
};

export default Websites;