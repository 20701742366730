import React from "react";

/**
 * Creates an image cjsx component
 * @param param0 The image component to display
 * @returns An image jsx element
 */
const Image = ({src, alt, isPixelated}) => {
    return (
        <img 
            src={src} 
            className="m-auto w-100" 
            alt={alt}
            style={
                isPixelated ?
                {imageRendering:'pixelated'}
                :{}
            }
        />
    );
};

export default Image;