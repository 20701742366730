import { Link } from "react-router-dom";
import { mediaLinks } from "../helpers/mediaLinks";
import { borderedBackground, flex_col, flex_row } from "../helpers/classnames";
import React from "react";

/**
 * Displays a page for the About Us section
 * @returns The About page
 */
const About = () => {
    return (
        <section
            className={`${flex_col} text-success`}
        >
            <article
                className={`${flex_row} m-auto justify-content-center ${borderedBackground}`}
            >
                <img 
                    className='display-image'
                    src={`${process.env.PUBLIC_URL}/NGLogo.png`}
                    alt="Nichathan Gaming"
                />
                <div
                    className={`${flex_col} display-text`}
                >
                    <h2
                        className="fw-bolder fs-1"
                    >Nichathan Gaming</h2>
                    <p>
                        <b>Nichathan Gaming</b> is an independent company conceptualized by Johnathan Nichols
                        in 2013 and created in in 2023 when the company started publishing games
                        and applications to the Google Play store. In early 2019, Nichathan Gaming 
                        started designing and publishing FERN websites and became more active with website development in 2024. In late 2024, Nichathan Gaming 
                        started publishing NPM packages for JavaScript and TypeScript.
                    </p>
                </div>
            </article>
            <article
                className={flex_col}
            >
                <h2
                    className={`${borderedBackground} fw-bolder m-auto w-auto`}
                >Media</h2>
                <div
                    className={`${flex_row} justify-content-center`}
                >
                    {
                        mediaLinks.map(({link, src, alt}, index) => {
                            return (
                                <Link 
                                    to={link}
                                    target="_blank"
                                    key={index}
                                    className=""
                                    style={{
                                        width:'5rem',
                                        height:'5rem'
                                    }}
                                ><img 
                                    src={src}
                                    alt={alt}
                                    className="img-fluid"
                                /></Link>
                            );
                        })
                    }
                </div>
            </article>
            <Link 
                to='mailto:jnichols@nichathangaming.com'
                target="_blank"
                className="btn btn-success m-auto"
            >Contact Us</Link>
        </section>
    );
};

export default About;