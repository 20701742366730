import discordImage from '../images/Discord.png';
import facebookImage from '../images/Facebook.png';
import linkedInImage from '../images/LinkedIn.png';
import youTubeImage from '../images/YouTube.png';

export const mediaLinks = [
    {
        link: 'https://www.facebook.com/NichathanGaming/',
        src: facebookImage,
        alt: 'Facebook',
    },
    {
        link: 'https://www.youtube.com/@nichathangaming',
        src: youTubeImage,
        alt: 'YouTube',
    },
    {
        link: 'https://www.linkedin.com/in/johnathan-nichols-571b87272/',
        src: linkedInImage,
        alt: 'LinkedIn',
    },
    {
        link: 'https://discordapp.com/users/nichathangaming',
        src: discordImage,
        alt: 'Discord',
    },
];
