import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
};

const reactGameCardsSlice = createSlice({
    name: 'reactGameCards',
    initialState,
    reducers: {
        setReactGameCards: (state, {payload}) => {
            state.data = payload;
        }
    }
});

export default reactGameCardsSlice.reducer;
export const {setReactGameCards} = reactGameCardsSlice.actions;