import axios from "axios";
import { setHomeCards } from "./features/homeCardsSlice";
import { setGooglePlayGamesCards } from "./features/googlePlayGamesCardsSlice";
import { setGooglePlayAppsCards } from "./features/googlePlayAppsCardsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setHostedWebsiteCards } from "./features/hostedWebsiteCardsSlice";
import { setReactGameCards } from "./features/reactGameCardsSlice";
import { setNPMCards } from "./features/npmCardsSlice";

//#region Getters
/**
 * GET's the card data from the server
 * @param routePath The path of the middleware
 * @param routeKey The collection with the cards
 * @param setDate The current date as an ISO string
 * @returns a card array retrieved from the server
 */
const getCardsFromDatabase = async (routePath, routeKey, setDate) => {
    const response = await axios.get('https://www.nichathangaming.com/'+routePath+routeKey);

    let cards = [];

    if(response && response.data){
        cards = response.data;
    }

    const newData = {
        setDate,
        cards
    };

    localStorage.setItem(routeKey, JSON.stringify(newData));

    return cards;
};

/**
 * Gets the cards from either the localStorage or server
 * @param routePath The path of the middleware
 * @param routeKey The collection with the cards
 * @returns An array of cards found
 */
const getCards = async (routePath, routeKey) => {
    const cardData = localStorage.getItem(routeKey);
    const today = new Date();

    if(cardData){
        const {setDate, cards} = JSON.parse(cardData);

        const savedDate = new Date(setDate);

        const timeDifference = today.getTime() - savedDate.getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);

        if(daysDifference < 1){
            return cards;
        }
        else{
            const cards = await getCardsFromDatabase(routePath, routeKey, today.toISOString());

            return cards;
        }
    }else{
        const cards = await getCardsFromDatabase(routePath, routeKey, today.toISOString());

        return cards;
    }
};

/**
 * Gets the cards for the homeCards collection
 * @param routePath The path of the middleware
 * @param dispatch A dispatch function to update the store
 */
const getHomeCards = async (routePath, dispatch) => {
    const cards = await getCards(routePath, 'homeCards');

    dispatch(setHomeCards(cards));
};

/**
 * Gets the cards for the googlePlayGamesCards collection
 * @param routePath The path of the middleware
 * @param dispatch A dispatch function to update the store
 */
const getGooglePlayGamesCards = async (routePath, dispatch) => {
    const cards = await getCards(routePath, 'googlePlayGamesCards');

    dispatch(setGooglePlayGamesCards(cards));
};

/**
 * Gets the cards for the googlePlayAppsCards collection
 * @param routePath The path of the middleware
 * @param dispatch A dispatch function to update the store
 */
const getGooglePlayAppsCards = async (routePath, dispatch) => {
    const cards = await getCards(routePath, 'googlePlayAppsCards');

    dispatch(setGooglePlayAppsCards(cards));
};

/**
 * Gets the cards for the hostedWebsiteCards collection
 * @param routePath The path of the middleware
 * @param dispatch A dispatch function to update the store
 */
const getHostedWebsiteCards = async (routePath, dispatch) => {
    const cards = await getCards(routePath, 'hostedWebsiteCards');

    dispatch(setHostedWebsiteCards(cards));
};

/**
 * Gets the cards for the reactGameCards collection
 * @param routePath The path of the middleware
 * @param dispatch A dispatch function to update the store
 */
const getReactGameCards = async (routePath, dispatch) => {
    const cards = await getCards(routePath, 'reactGameCards');

    dispatch(setReactGameCards(cards));
};

/**
 * Gets the cards for the npmCards collection
 * @param routePath The path of the middleware
 * @param dispatch A dispatch function to update the store
 */
const getNPMCards = async (routePath, dispatch) => {
    const cards = await getCards(routePath, 'npmCards');

    dispatch(setNPMCards(cards));
};
//#endregion

/**
 * Creates an async thunk to update the data at the routes
 */
export const dataLoader = createAsyncThunk(
    'dataLoader',
    async (_, {dispatch}) => {
        const routePath = 'global-firebase/';

        try{
            getHomeCards(routePath, dispatch);
        }catch(error){
            console.log('Error getting homeCards:', error);
        }
        
        try{
            getGooglePlayGamesCards(routePath, dispatch);
        }catch(error){
            console.log('Error getting GooglePlayGamesCards:', error);
        }
        
        try{
            getGooglePlayAppsCards(routePath, dispatch);
        }catch(error){
            console.log('Error getting GooglePlayAppsCards:', error);
        }
        
        try{
            getHostedWebsiteCards(routePath, dispatch);
        }catch(error){
            console.log('Error getting HostedWebsiteCards:', error);
        }
        
        try{
            getReactGameCards(routePath, dispatch);
        }catch(error){
            console.log('Error getting ReactGameCards:', error);
        }
        
        try{
            getNPMCards(routePath, dispatch);
        }catch(error){
            console.log('Error getting NPMCards:', error);
        }
    }
);