import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
};

const googlePlayAppsCardsSlice = createSlice({
    name: 'googlePlayAppsCards',
    initialState,
    reducers: {
        setGooglePlayAppsCards: (state, {payload}) => {
            state.data = payload;
        }
    }
});

export default googlePlayAppsCardsSlice.reducer;
export const {setGooglePlayAppsCards} = googlePlayAppsCardsSlice.actions;