import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import ScrollToTop from './components/ScrollToTop';
import About from './pages/About';
import GooglePlay from './pages/GooglePlay';
import NPM from './pages/NPM';
import Tutorials from './pages/Tutorials';
import Websites from './pages/Websites';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { useDispatch, useSelector } from 'react-redux';
import { changeAuth } from './features/authSlice';
import {dataLoader} from './dataLoader';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

const Admin = lazy(()=>import('./pages/admin/Admin'));
const Login = lazy(()=>import('./pages/Login'));

const App = () => {
  const {isAuthenticated} = useSelector((state)=>state.auth);
  const dispatch = useDispatch();
  
  //get auth
  useEffect(()=>{
    const unsubscribe = onAuthStateChanged(auth, async (newUser)=>{
      const idToken = newUser? await newUser.getIdToken(): null;
      
      const authContext = {
        isAuthenticated: !!newUser,
        idToken: idToken
      };

      dispatch(changeAuth(authContext));
    });

    return () => unsubscribe();
  }, [dispatch]);

  //get homeCards
  useEffect(()=>{
    dispatch(dataLoader());
  }, [dispatch]);

  return (
    <div
      className='user-select-none'
    >
      <NavBar />
      <main
        className='p-5 min-vh-100'
      >
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
            <Route path='/about' element={<About />} />
            <Route path='/google-play' element={<GooglePlay />} />
            <Route path='/npm' element={<NPM />} />
            <Route path='/tutorials' element={<Tutorials />} />
            <Route path='/websites' element={<Websites />} />
            <Route 
              path='/login' 
              element={
                isAuthenticated ? <Navigate to='/admin'/> : <Login/>
              }
            />
            <Route 
              path='/admin/*'
              element={
                isAuthenticated ? <Admin/> : <Navigate to='/' />
              }
            />
            <Route path='/' element={<Home />} />
            <Route path='*' element={<Navigate to='/'/>} />
          </Routes>
        </Suspense>
      </main>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default App;
