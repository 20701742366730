import { Link } from 'react-router-dom';
import tutImage from '../images/Tutorials.png';
import { borderedBackground, flex_col, flex_row } from '../helpers/classnames';
import React from 'react';

/**
 * Shows a page for Nichathan Gaming's tutorials.
 * @returns The Tutorials page
 */
const Tutorials = () => {
    return (
        <section
            className={`text-success ${flex_col}`}
        >
            <article
                className={`${flex_row} m-auto justify-content-center ${borderedBackground}`}
            >
                <img 
                    className='display-image'
                    src={tutImage}
                    alt="tutorials"
                />
                <div
                    className={`${flex_col} display-text`}
                >
                    <h2
                        className="fw-bolder fs-1"
                    >Nichathan Gaming Tutorials</h2>
                    <p>
                        <b>Nichathan Gaming</b> has been creating tutorials for as long as the company has been providing
                        programming solutions. Whenever Nichathan Gaming encounters an issue while programming that is not
                        well documented, Nichathan Gaming will create a tutorial on the solution for the issue on GitHub 
                        and/or YouTube. Nichathan Gaming enjoys creating tutorials and helping out struggling programmers.
                        The tutorials by Nichathan Gaming cover C#, C# for Unity, Fullstack Development and programming 
                        concepts such as path generation and pathfinding algorithms. 
                    </p>
                    <div
                        className={flex_row}
                    >
                        <Link 
                            className='btn btn-success m-auto'
                            to='https://www.youtube.com/@nichathangaming'
                            target='_blank'
                        >View <b>Nichathan Gaming</b> Tutorials on YouTube</Link>
                        <Link 
                            className='btn btn-success m-auto'
                            to='https://github.com/Nichathan-Gaming'
                            target='_blank'
                        >View <b>Nichathan Gaming</b> Tutorials on GitHub (Mostly C#)</Link>
                        <Link 
                            className='btn btn-success m-auto'
                            to='https://github.com/J-M-Nichols'
                            target='_blank'
                        >View <b>Nichathan Gaming</b> Tutorials on GitHub (Mostly Full Stack)</Link>
                    </div>
                </div>
            </article>
        </section>
    );
};

export default Tutorials;