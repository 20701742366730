import { useEffect, useState } from "react";

/**
 * Creates a button at the bottom right of the page when the page is not at the
 * top of the page that will scroll to the top of the page when clicked
 * @returns A jsx button to scroll to the top
 */
const ScrollToTop = () => {
    const [isAtTop, setIsAtTop] = useState(true);

    useEffect(()=>{
        const handleScroll = () => {
            setIsAtTop(window.scrollY===0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <button
            type="button"
            className={`btn btn-success btn-floating btn-lg rounded-circle position-fixed p-3 m-3 bottom-0 end-0 ${isAtTop?'':'visible'}`}
            id="btn-back-to-top"
            onClick={scrollToTop}
        >
            <i className="bi bi-arrow-up d-flex"></i>
        </button>
    );
};

export default ScrollToTop;