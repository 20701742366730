import { combineReducers } from "redux";
import homeCardReducer from './homeCardsSlice';
import { configureStore } from "@reduxjs/toolkit";
import authReducer from './authSlice';
import googlePlayAppsCardsReducer from './googlePlayAppsCardsSlice';
import googlePlayGamesCardsReducer from './googlePlayGamesCardsSlice';
import hostedWebsiteCardsReducer from './hostedWebsiteCardsSlice';
import reactGameCardsReducer from './reactGameCardsSlice';
import npmCardsReducer from './npmCardsSlice';

const rootReducer = combineReducers({
    homeCards: homeCardReducer,
    auth: authReducer,
    googlePlayAppsCards: googlePlayAppsCardsReducer,
    googlePlayGamesCards: googlePlayGamesCardsReducer,
    hostedWebsiteCards: hostedWebsiteCardsReducer,
    reactGameCards: reactGameCardsReducer,
    npmCards: npmCardsReducer,
});

const store = configureStore({
    reducer: rootReducer,
});

export default store;