import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
};

const googlePlayGamesCardsSlice = createSlice({
    name: 'googlePlayGamesCards',
    initialState,
    reducers: {
        setGooglePlayGamesCards: (state, {payload}) => {
            state.data = payload;
        }
    }
});

export default googlePlayGamesCardsSlice.reducer;
export const {setGooglePlayGamesCards} = googlePlayGamesCardsSlice.actions;