import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    idToken: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        changeAuth: (state, action) => {
            const {isAuthenticated, idToken} = action.payload;

            state.isAuthenticated = isAuthenticated;
            state.idToken = idToken;
        }
    }
});

export default authSlice.reducer;
export const {changeAuth} = authSlice.actions;