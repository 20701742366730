import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
};

const npmCardsSlice = createSlice({
    name: 'npmCards',
    initialState,
    reducers: {
        setNPMCards: (state, {payload}) => {
            state.data = payload;
        }
    }
});

export default npmCardsSlice.reducer;
export const {setNPMCards} = npmCardsSlice.actions;