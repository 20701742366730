import { useSelector } from "react-redux";
import Card from "../components/Card";
import { borderedBackground, flex_col, flex_row } from "../helpers/classnames";
import React from "react";

/**
 * Shows the home page for the Nichathan Gaming website with blog articles to notify users about 
 * the important news.
 * @returns The home page
 */
const Home = () => {
    const cards = useSelector(state=>state.homeCards);

    return (
        <section
            className={`text-success ${flex_col}`}
        >
            <article
                className={`${flex_row} m-auto justify-content-center ${borderedBackground}`}
            >
                <img 
                    className='display-image'
                    src={`${process.env.PUBLIC_URL}/NGLogo.png`}
                    alt="Nichathan Gaming"
                />
                <div
                    className={`${flex_col} display-text`}
                >
                    <h2
                        className="fw-bolder fs-1"
                    >Nichathan Gaming</h2>
                    <p>
                        <b>Nichathan Gaming</b> is an independent company conceptualized by Johnathan Nichols
                        in 2013 and created in in 2023 when the company started publishing games
                        and applications to the Google Play store. In early 2019, Nichathan Gaming 
                        started designing and publishing FERN websites and became more active with website development in 2024. In late 2024, Nichathan Gaming 
                        started publishing NPM packages for JavaScript and TypeScript.
                    </p>
                </div>
            </article>
            <article
                className={`${flex_row} justify-content-center`}
            >
                {cards.data.map((card, index)=>{
                    return <Card 
                        key={index}
                        {...card}
                    />
                })}
            </article>
        </section>
    );
};

export default Home;