import { Link } from "react-router-dom";
import { mediaLinks } from "../helpers/mediaLinks";
import { flex_col, flex_row } from "../helpers/classnames";

/**
 * Creates a footer for the bottom of the pages
 * @returns The footer component
 */
const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer
            className={`text-light bg-dark bg-opacity-75 p-3 rounded-top border border-success ${flex_col}`}
        >
            <div
                className={`${flex_row} flex-fill pt-3`}
            >
                <div
                    id="leftFooter"
                    className={`flex-grow-1 col-md-3 text-center ${flex_col}`}
                >
                    <img 
                        className="img-fluid col-md-3 m-auto"
                        src={`${process.env.PUBLIC_URL}/NGLogo.png`}
                        alt="Nichathan Gaming"
                    />
                    <h2
                        className="fw-bolder"
                    >Nichathan Gaming</h2>
                    <p
                        className="text-wrap"
                    >
                        Nichathan Gaming is a programming solutions company that
                        creates Full Stack MERN/FERN website and Unity games and 
                        applications.
                    </p>
                </div>
                <div 
                    id="rightFooter"
                    className={`${flex_col} flex-grow-1 col-md-7`}
                >
                    <div
                        className=""
                    >
                        <h3
                            className="fw-bolder text-center"
                        >Find us on social media</h3>
                        <div
                            className={`${flex_row} justify-content-center`}
                        >
                            {
                                mediaLinks.map(({link, src, alt}, index) => {
                                    return (
                                        <Link 
                                            to={link}
                                            target="_blank"
                                            key={index}
                                            className=""
                                            style={{
                                                width:'2rem',
                                                height:'2rem'
                                            }}
                                        ><img 
                                            src={src}
                                            alt={alt}
                                            className="img-fluid"
                                        /></Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <Link 
                        to='mailto:jnichols@nichathangaming.com'
                        target="_blank"
                        className="fw-bolder text-center text-light"
                    >Email: jnichols@nichathangaming.com</Link>
                </div>
            </div>
            <div
                id="footerCopyright"
                className="text-center"
            >
                Copyright © 2019 - {currentYear} Nichathan Gaming All rights reserved
            </div>
        </footer>
    );
};

export default Footer;